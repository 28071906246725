<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-12 text-right">
				<a-button class="btn btn-info" icon="plus" @click="onAddRecord">Nuevo Cliente</a-button>
			</div>
		</div>
		<div class="row pt10">
			<div class="col-md-12">
				<a-input-search v-model="searchText" ref="searchInput" placeholder="Ingresa parámetros de búsqueda" enter-button @search="onSearch" />
			</div>
		</div>
		<div class="row pt20" v-if="clientsList.length">
			<div class="col-md-12">
				<a-table :columns="columns" :dataSource="clientsList" rowKey="id">
					<span slot="id_type" slot-scope="record">
						{{ record.id }}
					</span>
					<div slot="client_name" slot-scope="record">{{ `${record.client_name} ${record.client_surname}` }} {{ record.client_last_surname | replaceIfLogicalFalse('') }}</div>
					<span slot="phones" slot-scope="record">
						{{ record.primary_phone | replaceIfLogicalFalse('-No establecido-') }}
						<br />
						{{ record.secondary_phone | replaceIfLogicalFalse('-No establecido-') }}
					</span>
					<span slot="active" slot-scope="record">
						{{ record.stock }}
						<br />
						<a-tag color="green" v-if="record.active">Activo</a-tag>
						<a-tag color="red" v-else>Inactivo</a-tag>
					</span>
					<div slot="dates" slot-scope="record">
						{{ moment(record.created_at).format('DD-MM-YYYY') }}
					</div>
					<span slot="action" slot-scope="record">
						<a-button class="ml5" type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
					</span>
				</a-table>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
	name: 'clientsView',
	computed: {
		...mapGetters('clients', ['clientsList']),
		spinnerLoaderLabel() {
			return this.$store.state.clients.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.clients.spinnerLoader
		},
	},
	data() {
		return {
			searchText: '',
			columns: [
				{
					title: 'ID',
					scopedSlots: { customRender: 'id_type' },
					align: 'center',
				},
				{
					title: 'Nombre del cliente',
					scopedSlots: { customRender: 'client_name' },
				},
				{
					title: 'Teléfono / Celular',
					scopedSlots: { customRender: 'phones' },
				},
				{
					title: 'Email',
					dataIndex: 'email',
					key: 'email',
					align: 'center',
				},
				{
					title: 'Status',
					scopedSlots: { customRender: 'active' },
					align: 'center',
				},
				{
					title: 'Registro',
					scopedSlots: { customRender: 'dates' },
					align: 'center',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
				},
			],
		}
	},
	methods: {
		moment,
		onAddRecord() {
			this.$router.push('/pos/clients/create')
		},
		onSearch() {
			if (this.searchText == '') {
				Swal.fire({
					title: 'Catálogo de Clientes',
					text: 'Debes ingresar texto para la búsqueda',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			} else {
				this.$store.dispatch('clients/SEARCH', { searchText: this.searchText })
			}
		},
		onEditRecord(id) {
			this.$store.dispatch('clients/GET_ONE', { id })
		},
		onToggleRecord(id, status) {
			Swal.fire({
				title: 'Atención',
				text: `¿Desea ${status ? 'deshabilitar' : 'habilitar'} el registro?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('clients/TOGGLE', id)
				}
			})
		},
	},
}
</script>
